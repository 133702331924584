import React from 'react';

import FooterInfo from '../components/FooterInfo';
import FooterSocial from '../components/FooterSocial';
import FooterLinks from '../components/FooterLinks';

const DEFAULT_CLASSNAME: string = `footer`;
const FOOTERCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__footercontainer`;
const COPYRIGHT_CLASSNAME: string = `${DEFAULT_CLASSNAME}__copyright`;

const Footer = (): JSX.Element => {
    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={FOOTERCONTAINER_CLASSNAME}>
                <FooterInfo />
                <FooterSocial />
                <FooterLinks />
            </div>
            <div className={COPYRIGHT_CLASSNAME}>© 2024 russdando.com</div>
            {/* <div>Icons made by <a href="https://www.freepik.com" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div> */}
        </div>
    );
};

export default Footer;