import React from 'react';

import site from '../data/site.json';

const DEFAULT_CLASSNAME: string = `footerinfo`;
const HEADER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__header`;
const SUBHEADER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__subheader`;
const TEXT_CLASSNAME: string = `${DEFAULT_CLASSNAME}__text`;

const FooterInfo = (): JSX.Element => {
    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={HEADER_CLASSNAME}>Information</div>
            <div className={SUBHEADER_CLASSNAME}>Phone</div>
            <div className={TEXT_CLASSNAME}>{site.general.phonenumber}</div>
            <div className={SUBHEADER_CLASSNAME}>Email</div>
            <div className={TEXT_CLASSNAME}>{site.general.email}</div>
        </div>
    );
}

export default FooterInfo;