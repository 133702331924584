import 'babel-polyfill';
import React, { 
    useState, 
    useCallback
} from 'react';
import { Helmet } from 'react-helmet'
import HamburgerMenu from 'react-hamburger-menu';

//import Infobar from './Infobar';
import Navbar from './Navbar';
//import NavbarScroller from './NavbarScroller';
import Footer from './Footer';
import Sidebar from './Sidebar';

import '../styles/index.scss';

const DEFAULT_CLASSNAME: string = `background`;
const HAMBURGERCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__hamburgercontainer`;
const HAMBURGER_CLASSNAME: string = `${HAMBURGERCONTAINER_CLASSNAME}__hamburger`;
//const PAGECONTENT_CLASSNAME: string = `${DEFAULT_CLASSNAME}__pagecontent`;

const Layout = ({children}): JSX.Element => {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false);

    function setBurger() {
        setSidebarOpen(!sidebarOpen);
    }

    const onLinkClick = useCallback(() => {
        setSidebarOpen(!sidebarOpen);
    }, [sidebarOpen]);

    const setBurgerCallback = useCallback(() => {
        setBurger();
    }, [sidebarOpen]);

    return (
        <div className={DEFAULT_CLASSNAME}>
            <Helmet title='Russ Dando Software Development'>
            </Helmet>
            <Sidebar isOpen={sidebarOpen} linkClick={onLinkClick} />
            <div style={{height: '20px', backgroundColor: 'rgb(36, 85, 150)'}}></div>
            {/* <Infobar  /> */}
            <Navbar />
            {/* <NavbarScroller /> */}
            <div className={HAMBURGERCONTAINER_CLASSNAME}>
                <HamburgerMenu
                    color={'white'} 
                    className={HAMBURGER_CLASSNAME} 
                    isOpen={sidebarOpen} 
                    menuClicked={setBurgerCallback}
                />
            </div>
            {/* <div className={PAGECONTENT_CLASSNAME}> */}
                {children}
            {/* </div> */}
            <Footer  />
        </div>
    );
}

export default Layout;