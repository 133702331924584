import React from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';

interface ISidebarProps {
    isOpen: boolean;
    linkClick: () => void;
}

const DEFAULT_CLASSNAME: string = `sidebarcontainer`;
const SIDEBARLINKSCONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__sidebarlinkscontainer`;
const SIDEBARLINKCONTAINER_CLASSNAME: string = `${SIDEBARLINKSCONTAINER_CLASSNAME}__sidebarlinkcontainer`;
const SIDEBARLINK_CLASSNAME: string = `${SIDEBARLINKCONTAINER_CLASSNAME}__sidebarlink`;
const SIDEBAR_ACTIVELINK_CLASSNAME: string = `${SIDEBARLINKCONTAINER_CLASSNAME}__sidebarlink--active`;

const Sidebar = (props: ISidebarProps): JSX.Element => {
    const { 
        isOpen, 
        linkClick
    } = props;
    
    const openClass = classNames(DEFAULT_CLASSNAME, {
        [`${DEFAULT_CLASSNAME}--open`]: isOpen,
        [`${DEFAULT_CLASSNAME}--closed`]: !isOpen
    });

    return (
        <div className={openClass}>
            <div className={SIDEBARLINKSCONTAINER_CLASSNAME}>
                <div className={SIDEBARLINKCONTAINER_CLASSNAME}>
                    <Link 
                        className={SIDEBARLINK_CLASSNAME} 
                        activeClassName={SIDEBAR_ACTIVELINK_CLASSNAME} 
                        to={'/'}
                        onClick={linkClick}
                    >
                    Home
                    </Link>
                </div>
                <div className={SIDEBARLINKCONTAINER_CLASSNAME}>
                    <Link 
                        className={SIDEBARLINK_CLASSNAME} 
                        activeClassName={SIDEBAR_ACTIVELINK_CLASSNAME} 
                        to={'/about'}
                        onClick={linkClick}
                    >
                    About
                    </Link>
                </div>
                <div className={SIDEBARLINKCONTAINER_CLASSNAME}>
                    <Link 
                        className={SIDEBARLINK_CLASSNAME} 
                        activeClassName={SIDEBAR_ACTIVELINK_CLASSNAME} 
                        to={'/skills'}
                        onClick={linkClick}
                    >
                    Skills
                    </Link>
                </div>
                <div className={SIDEBARLINKCONTAINER_CLASSNAME}>
                    <Link 
                        className={SIDEBARLINK_CLASSNAME} 
                        activeClassName={SIDEBAR_ACTIVELINK_CLASSNAME} 
                        to={'/projects'}
                        onClick={linkClick}
                    >
                    Projects
                    </Link>
                </div>
                <div className={SIDEBARLINKCONTAINER_CLASSNAME}>
                    <Link 
                        className={SIDEBARLINK_CLASSNAME} 
                        activeClassName={SIDEBAR_ACTIVELINK_CLASSNAME} 
                        to={'/contact'}
                        onClick={linkClick}
                    >
                    Contact
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Sidebar;