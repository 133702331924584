import React from 'react';
import { Link } from 'gatsby';

import site from '../data/site.json';

const DEFAULT_CLASSNAME: string = 'navbar';
const NAVBAR_TITLE_CLASSNAME: string = `${DEFAULT_CLASSNAME}__title`;
const NAVBAR_LINKS_CLASSNAME: string = `${DEFAULT_CLASSNAME}__links`;
const NAVBAR_LINK_CLASSNAME: string = `${DEFAULT_CLASSNAME}__link`;
const NAVBAR_ACTIVELINK_CLASSNAME: string = `${DEFAULT_CLASSNAME}__link--active`;

const Navbar = (): JSX.Element => {
    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={NAVBAR_TITLE_CLASSNAME}>{site.general.name}</div>
            <div className={NAVBAR_LINKS_CLASSNAME}>            
                <Link className={NAVBAR_LINK_CLASSNAME} activeClassName={NAVBAR_ACTIVELINK_CLASSNAME} to='/'>Home</Link>
                <Link className={NAVBAR_LINK_CLASSNAME} activeClassName={NAVBAR_ACTIVELINK_CLASSNAME} to='/about'>About</Link>
                <Link className={NAVBAR_LINK_CLASSNAME} activeClassName={NAVBAR_ACTIVELINK_CLASSNAME} to='/skills'>Skills</Link>
                <Link className={NAVBAR_LINK_CLASSNAME} activeClassName={NAVBAR_ACTIVELINK_CLASSNAME} to='/projects'>Projects</Link>
                {/* <Link className={NAVBAR_LINK_CLASSNAME} activeClassName={NAVBAR_ACTIVELINK_CLASSNAME} to='/resume'>Resume</Link> */}
                {/* <Link className={NAVBAR_LINK_CLASSNAME} activeClassName={NAVBAR_ACTIVELINK_CLASSNAME} to='/blog'>Blog</Link> */}
                <Link className={NAVBAR_LINK_CLASSNAME} activeClassName={NAVBAR_ACTIVELINK_CLASSNAME} to='/contact'>Contact</Link>
            </div>
        </div>
    );
};

export default Navbar;