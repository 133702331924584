import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

const DEFAULT_CLASSNAME: string = `footersocial`;
const HEADER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__header`;
const IMAGECONTAINER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__imagecontainer`;
const TEXT_CLASSNAME: string = `${DEFAULT_CLASSNAME}__text`;

const FooterSocial = (): JSX.Element => {
    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={HEADER_CLASSNAME}>Social</div>
            <div className={IMAGECONTAINER_CLASSNAME}>
                <a href={'https://github.com/RJDNet?tab=repositories'} target="_blank"><StaticImage src={'../images/github.png'} alt={'github'} /></a>
            </div>
            <div className={TEXT_CLASSNAME}>View my Github profile for code on various projects and other work.</div>
            <div className={IMAGECONTAINER_CLASSNAME}>
                <a href={'https://www.linkedin.com/in/russ-dando-6aa9b2160/'} target="_blank"><StaticImage src={'../images/linkedin.png'} alt={'linkedin'} /></a>
            </div>
            <div className={TEXT_CLASSNAME}>View my LinkedIn profile.</div>
        </div>
    );
}

export default FooterSocial;