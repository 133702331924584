import React from 'react';
import { Link } from 'gatsby';

const DEFAULT_CLASSNAME: string = `footerlinks`;
const HEADER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__header`;
const SUBHEADER_CLASSNAME: string = `${DEFAULT_CLASSNAME}__subheader`;
const LINK_CLASSNAME: string = `${SUBHEADER_CLASSNAME}__link`;
const TEXT_CLASSNAME: string = `${DEFAULT_CLASSNAME}__text`;

const FooterLinks = (): JSX.Element => {
    return (
        <div className={DEFAULT_CLASSNAME}>
            <div className={HEADER_CLASSNAME}>Links</div>
            <div className={SUBHEADER_CLASSNAME}>
                <Link className={LINK_CLASSNAME} to={'/'}>Home</Link>
            </div>
            <div className={TEXT_CLASSNAME}>Back to the homepage.</div>
            <div className={SUBHEADER_CLASSNAME}>
                <Link to={'/about'} className={LINK_CLASSNAME}>About</Link>
            </div>
            <div className={TEXT_CLASSNAME}>Information about my background.</div>
            <div className={SUBHEADER_CLASSNAME}>
                <Link to={'/skills'} className={LINK_CLASSNAME}>Skills</Link>
            </div>
            <div className={TEXT_CLASSNAME}>A comprehensive list of my skills.</div>
            <div className={SUBHEADER_CLASSNAME}>
                <Link to={'/projects'} className={LINK_CLASSNAME}>Projects</Link>
            </div>
            <div className={TEXT_CLASSNAME}>A collection of past projects.</div>
            {/* <div className={SUBHEADER_CLASSNAME}>
                <Link to={'/blog'} className={LINK_CLASSNAME}>Blog</Link>
            </div>
            <div className={TEXT_CLASSNAME}>Read my latest blog posts.</div> */}
            <div className={SUBHEADER_CLASSNAME}>
                <Link to={'/contact'} className={LINK_CLASSNAME}>Contact</Link>
            </div>
            <div className={TEXT_CLASSNAME}>Get in contact via email.</div>
        </div>
    );
}

export default FooterLinks;